import _ from 'lodash';
const moment = require('moment');

function get (username, token, filters, resolve, reject, complete) {
  console.log('notifications.get ...');

  const params = new URLSearchParams(_.omitBy(filters, f => f==null||_.isEmpty(f)));
  
  this.$api.COM.get('/com/notifications/'+username+'/'+token, { params })
  .then(response => {
    console.log('notifications.get => ', response);
    const data = _.map(response.data, n => {
      return {
        ...n,
        sent: moment.utc(n.sent).local().format('YYYY-MM-DD HH:mm:ss'),
        read: !!n.read ? moment.utc(n.read).local().format('YYYY-MM-DD HH:mm:ss') : null,
      }
    })
    resolve(data);
  })
  .catch(error => {
    this.handleError(error);
    if (!_.isNil(reject)) reject(error);
  })
  .finally(() => {
    // callback function
    if (!_.isNil(complete)) complete();
  });
}

function read (username, token, notifications, resolve, reject, complete) {
  console.log('notifications.read ...');
  
  this.$api.COM.post('/com/notifications/'+username+'/'+token, notifications)
  .then(response => {
    console.log('notifications.read => ', response);
    resolve(response.data);
  })
  .catch(error => {
    this.handleError(error);
    if (!_.isNil(reject)) reject(error);
  })
  .finally(() => {
    // callback function
    if (!_.isNil(complete)) complete();
  });
}

export {
  get,
  read
}
