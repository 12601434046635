<template>
  <v-menu 
    v-model="view.toggle"
    :close-on-content-click="false"
    offset-y
    max-width="360"
  >
    <template v-slot:activator="menu">
      <v-tooltip bottom>
        <template v-slot:activator="tooltip">
          <v-btn
            v-show="!isEmpty"
            fab
            icon
            small
            :loading="loading"
            :color="hasUnread ? 'primary' : 'grey lighten-1'"
            class="notifications-btn mx-1"
            v-bind="{ ...menu.attrs, ...tooltip.attrs }"
            v-on="{ ...menu.on, ...tooltip.on }"
          >
            <v-icon dense>
              {{ hasUnread ? icons.new : icons.bell }}
            </v-icon>
          </v-btn>
        </template>
        Notificações
      </v-tooltip>
    </template>
    <v-card 
      min-width="320"
      class="notifications-list"
    >
      <v-subheader class="text-overline grey--text">
        Notificações
      </v-subheader>
      <v-divider />
      <v-list 
        max-height="50vh"
        max-width="320"
        class="scrollable"
      >
        <v-list-item
          v-for="item in list"
          :key="'notification-'+item.id"
          :class="[{ 'unread': item.read==null }, (item.read==null ? 'primary' : 'grey')+'--text']"
          class="notification"
          @click="open(item)"
        >
          <v-list-item-content>
            <v-list-item-title 
              class="text-subtitle-2 grey--text text--darken-3 pb-1"
              v-html="formatText(item.title)"
            />
            <v-list-item-subtitle 
              class="text-body-2 text-untruncate grey--text text--darken-2"
              v-html="formatText(item.text)"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<style>

  .notification {
    transition: opacity .15s ease-out;
  }
  .notification:not(:hover) {
    opacity: .64;
  }
  .notification.unread {
    opacity: 1;
    border-left: 3px solid;
  }
  
</style>

<script>
  import { 
    mdiBell,
    mdiBellBadge,
  } from '@mdi/js';
  import services from '@/services'
  import { sync } from 'vuex-pathify'
  import { get as $get, read as $read } from '@/api/notifications'
  const moment = require('moment');

  export default {
    props: {
      visible: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      user: {
        type: Object,
        default: () => {}
      }
    },

    data: () => ({
      icons: {
        bell: mdiBell,
        new: mdiBellBadge,
      },
      view: {
        toggle: false,
        timer: null,
        interval: 300000,
      },
      loading: false,
      updated: false,
    }),

    computed: {
      notifications: sync('user/notifications'),

      list () {
        const list = this.notifications.data;
        return _.orderBy(_.values(list), ['sent'], ['desc'])
      },

      hasUnread () {
        return _.some(this.list, ['read', null]);
      },

      isEmpty () {
        return _.size(this.list) == 0;
      },
    },

    watch: {
      visible: {
        immediate: true,
        handler (b) {
          const updatedAt = this.notifications.updatedAt;
          if (b&&(_.isNil(updatedAt)||moment().diff(updatedAt, 'ms')>this.view.interval)) {
            this.get()
          }
        }
      },
      'view.toggle' (b) {
        if (b) {
          if (!this.loading) this.get();
        }else{
          if (this.hasUnread) this.read()
        }
      }
    },

    filters: {
      format (text, formatter) {
        return formatter(text);
      }
    },

    methods: {
      ...services,
      $get,
      $read,

      get () {
        if (!_.isNil(this.view.timer)) clearTimeout(this.view.timer);
        this.loading = true;
        this.updated = false;
        this.$get(
          this.user.username,
          this.user.auth.token,
          null,
          (data) => {
            this.notifications.data = Object.assign({}, _.keyBy(data, 'id'));
            this.notifications.updatedAt = moment().format('YYYY-MM-DD HH:mm:ss');
            this.updated = true;
          },
          (error) => { 
            if (error.response.status==401) {
              this.getout();
              this.$emit('alert', 'Sua sessão expirou...')
            }else{
              // this.$emit('alert', 'Não foi possível criar a fila, tente novamente.');
            }
          },
          () => { 
            this.loading = false;
            this.view.timer = setTimeout(($) => {
              this.job()
            }, this.view.interval, this);
          }
        )
      },
      read () {
        this.loading = true;
        this.updated = false;
        const notifications = _.map(_.filter(this.list, ['read', null]), 'id')
        this.$read(
          this.user.username,
          this.user.auth.token,
          notifications,
          (data) => {
            _.each(notifications, n => {
              const data = this.notifications.data[n];
              this.notifications.data[n] = Object.assign(data, { ...data, read: moment().valueOf() });
            })
            this.updated = true;
          },
          (error) => { 
            if (error.response.status==401) {
              this.getout();
              this.$emit('alert', 'Sua sessão expirou...')
            }else{
              // this.$emit('alert', 'Não foi possível criar a fila, tente novamente.');
            }
          },
          () => { 
            this.loading = false;
          }
        )
      },
      open (notification) {
        if (!!notification.reference) {
          const [type, ref] = _.split(notification.reference, '#');
          let url = null;
          switch (type) {
            case 'trello':
              url = ref;
              break;
            
            case 'ticket':
              url = 'https://mobees.atlassian.net/browse/' + ref;
              break;
          
            default:
              break;
          }
          if (!!url) {
            window.open(url, '_blank');
          }
        }
        this.read();
      },
      job () {
        if (this.visible&&!this.loading) {
          if (!_.isNil(this.view.timer)) clearTimeout(this.view.timer);
          this.get()
        }
      }
    },

    mounted () {
      // this.get()
    },
    beforeDestroy () {
      if (_.isNil(this.view.timer)) clearTimeout(this.view.timer);
    }
  }
</script>